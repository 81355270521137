import React from "react";
import { ReactComponent as Logo } from "../assets/discord.svg";
import "./DiscordButton.css";

const DiscordButton = () => {
  const handleClick = () => {
    window.location.href =
      "https://discord.com/oauth2/authorize?client_id=1262647743717965868";
  };

  return (
    <div className="discordButton" onClick={handleClick}>
      <div className="logoWrapper">
        <Logo />
      </div>
      <p>Add to Server</p>
    </div>
  );
};

export default DiscordButton;
