import React from "react";

const style = {
  fontFamily: "Fira Code",
  color: "#888",
  letterSpacing: "-1px",
  fontSize: "20px",
  marginTop: "0px",
};

const Description = () => {
  return <p style={style}>Effortless Message Saver for Discord</p>;
};

export default Description;
