import "./faq.css";

function FAQPage() {
  return (
    <div className="faq">
      <h1 className="faqTitle">Frequently Asked Questions</h1>

      <div className="FAQContent">
        <h3>Q. Could not receive any messages from bot.</h3>
        <p>
          Please check your Discord privacy settings. Ensure that the option{" "}
          <b>Allow direct messages from server members</b> is enabled. <br />
          <br />
          You can find this option under{" "}
          <b>
            Privacy & Safety &gt; User Settings &gt; Server Privacy defaults
          </b>
          .
        </p>
        <img src="/faq1.png" />
      </div>
    </div>
  );
}

export default FAQPage;
