import "./changelog.css";

function ChangeLogPage() {
  return (
    <div className="changelog">
      <h1 className="changelogTitle">ChangeLogs</h1>

      <div className="changelogContent"></div>

      <div className="changelogContent">
        <div className="changelogContentTitle">
          <b>v1.1.0</b>
          <a>2024-08-16</a>
        </div>
        <p>
          <img src="/update1.png" /> <br />
          <br />- Message format has been changed.
          <br /> - If your messenger contains photos, a representative image
          will be saved as well.
        </p>
        <br />
        <div className="changelogContentTitle">
          <b>v1.0.0</b>
          <a>2024-08-11</a>
        </div>
        <p>First version of KeepMsg bot.</p>
      </div>
    </div>
  );
}

export default ChangeLogPage;
