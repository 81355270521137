import { Link, NavLink } from "react-router-dom";
import "./Navigator.css";

const NavigationBar = () => {
  return (
    <div className="navigator">
      <Link to="/" className="navigatorTitle">
        🗄️ KeepMsg
      </Link>
      <div className="rightMenu">
        <NavLink
          to="faq"
          className={({ isActive }) => (isActive ? "activeLink" : "")}
        >
          FAQ
        </NavLink>
        <NavLink
          to="changelog"
          className={({ isActive }) => (isActive ? "activeLink" : "")}
        >
          ChangeLog
        </NavLink>
      </div>
    </div>
  );
};

export default NavigationBar;
