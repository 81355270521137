import Title from "../components/Title";
import Description from "../components/Description";
import DiscordButton from "../components/DiscordButton";
import BuyMeACoffeeButton from "../components/BuyMeACoffee";
import NavigationBar from "../components/Navigator";

function IndexPage() {
  return (
    <div className="App">
      <header className="App-header">
        <Title />
        <Description />
        <DiscordButton />
        <BuyMeACoffeeButton />
      </header>
    </div>
  );
}

export default IndexPage;
