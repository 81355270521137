import React from "react";

const style = {
  color: "black",
  fontFamily: "Fira Code",
  marginBottom: "12px",
};

const Title = () => {
  return <h1 style={style}>🗄️ KeepMsg</h1>;
};

export default Title;
