import "./App.css";
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  Routes,
} from "react-router-dom";
import IndexPage from "./routes/main";
import FAQPage from "./routes/faq";
import NavigationBar from "./components/Navigator";
import ChangeLogPage from "./routes/changelog";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: [
        {
          index: true,
          element: <IndexPage />,
        },
        {
          path: "/faq",
          element: <FAQPage />,
        },
        {
          path: "/changelog",
          element: <ChangeLogPage />,
        },
      ],
    },
  ]);

  return <RouterProvider router={router} />;
}

function Layout() {
  return (
    <>
      <NavigationBar />
      <Outlet />
    </>
  );
}

export default App;
